import React from 'react';
import reactStringReplace from 'react-string-replace';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import staticTexts from 'texts';

const Footer = () => {
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const customStyles = { ...defaultDashboardStyles };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  let termsLink = reactStringReplace(
    staticTexts.SignUpDashboardTermsLink,
    '@terms',
    (match, i) => (
      <Link
        key={i}
        href={staticTexts.termsLinkUrl}
        className={classes.link}
        target={'_blank'}
        data-id="terms-link"
      >
        {staticTexts.termsLinkTitle}
      </Link>
    )
  );

  termsLink = reactStringReplace(termsLink, '@privacy', (match, i) => (
    <Link
      key={i * 55}
      href={staticTexts.privacyLinkUrl}
      className={classes.link}
      target={'_blank'}
      data-id="privacy-link"
    >
      {staticTexts.privacyLinkTitle}
    </Link>
  ));

  return (
    <Typography variant="subtitle2" component="div" className={classes.terms}>
      {termsLink}
      <div>
        <Link
          href={'#cookies'}
          className={classes.link}
          data-id="cookies-link"
          onClick={(e) => {
            e.preventDefault();
            window?.transcend?.showConsentManager({
              viewState: 'CompleteOptions',
            });
          }}
        >
          {staticTexts.cookiesLinkTitle}
        </Link>
      </div>
    </Typography>
  );
};

export default Footer;
