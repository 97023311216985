const INITIAL_STATE = {
  status: 'loading',
  config: {},
};

const applySetAccounts = (state, action) => ({
  status: 'loaded',
  config: action.payload,
});

function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_CONFIG': {
      if (action.payload) {
        return applySetAccounts(state, action);
      } else {
        return false;
      }
    }
    default:
      return state;
  }
}

export default configReducer;
