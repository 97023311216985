import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { db } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import staticTexts from 'texts';

const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#59CD90',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#59CD90',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const PublicDeviceSwitch = (props) => {
  const { updateSession } = db;

  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const publicComputer = useSelector(
    (state) => state.sessionDBState.data.publicComputer
  );

  const useStyles = makeStyles(() => defaultDashboardStyles);
  const classes = useStyles();
  const remember = useSelector(
    (state) => state.sessionDBState.data.rememberCredentials
  );
  const dispatch = useDispatch();

  const handleRememberMeChange = () => {
    dispatch(
      updateSession({
        rememberCredentials: !remember,
      })
    );
  };

  return (
    <FormControlLabel
      className={props.noSpacing ? classes.marginHelper : ''}
      control={
        <ToggleSwitch
          checked={remember}
          onChange={handleRememberMeChange}
          color="primary"
          disabled={publicComputer}
          data-id="public-device-switch"
        />
      }
      label={staticTexts.PrivateDevice}
    />
  );
};

export default PublicDeviceSwitch;
