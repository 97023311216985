import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as routes from 'constants/routes';
import staticTexts from 'texts';
import Preloader from 'components/Preloader/Preloader';
import { analytics, db, getCustomRoute } from 'actions';

const UserDashboard = () => {
  const { updateSession } = db;

  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountDataStatus = useSelector((state) => state.accountState.status);

  useEffect(() => {
    if (authUser === false) {
      history.push(routes.SIGN_UP);
    }
  }, [authUser, history]);

  useEffect(() => {
    analytics.page('User Dashboard');

    // Extend the session for remembered users.
    dispatch(
      updateSession({
        rememberCredentials: true,
      })
    );

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (accountDataStatus === 'loaded') {
      // Move to next step if account data is loaded.
      getCustomRoute(history);
    }
  }, [staticTexts, accountDataStatus, history]);

  return <Preloader title={staticTexts.UserDashboardLoading} />;
};

export default UserDashboard;
