import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';

import SignInWithFacebook from 'components/Sign/SignInWithFacebook';
import PublicDeviceSwitch from 'components/Common/PublicDeviceSwitch';
import SignInWithGoogle from 'components/Sign/SignInWithGoogle';
import ContainerBox from 'components/Common/ContainerBox';
import * as routes from 'constants/routes';

import { analytics, db, getCustomRoute } from 'actions';
import staticTexts from 'texts';
import Footer from 'components/Common/Footer';

const styleOverride = {};

const SignIn = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );

  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  const history = useHistory();

  /**
   * Redirect user to the next login page.
   *
   * Usually in case of Facebook login next page
   * is an email verification for newly added user.
   */
  useEffect(() => {
    if (accountData) {
      if (
        accountData?.first_name &&
        accountData?.last_name &&
        accountData?.roles &&
        accountData?.member_id
      ) {
        // Redirect for logged in user.
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  useEffect(() => {
    if (authUser && !accountData) {
      // Load user's account info.
      dispatch(db.fetchAccountInfo(authUser));
    } else {
      analytics.page('Sign In Dashboard');
    }
    // eslint-disable-next-line
  }, [authUser, accountData])

  const signLink = (e, route) => {
    e.preventDefault();
    history.push(route);
  };

  let registerText = reactStringReplace(
    staticTexts.SignInDashboardSignUpLink,
    '@signup',
    (match, i) => (
      <Link
        key={i}
        href={'#'}
        onClick={(e) => signLink(e, routes.SIGN_UP)}
        className={classes.link}
        data-id="signup-link"
      >
        {staticTexts.SignUp}
      </Link>
    )
  );

  return (
    <ContainerBox>
      <Typography variant="h1" className={classes.title} component="h1">
        {staticTexts.SignInDashboardTitle}
      </Typography>

      <Typography
        variant="body2"
        className={classes.description}
        component="h2"
      >
        {staticTexts.SignInDashboardDescription}
      </Typography>

      <SignInWithGoogle style={customStyles} text={staticTexts.GoogleSignIn} />

      <SignInWithFacebook
        style={customStyles}
        text={staticTexts.FacebookSignIn}
      />

      <Button
        className={classes.button}
        onClick={() => history.push(routes.SIGN_IN_EMAIL)}
        variant={'outlined'}
        color="secondary"
        disableElevation
        fullWidth
        size="large"
        data-id="signin-dashboard-button"
      >
        <EmailIcon className={classes.icon} />
        {staticTexts.SignInDashboardButtonEmail}
      </Button>

      <PublicDeviceSwitch noSpacing={true} />

      <Typography variant="body2" component="div">
        {registerText}
      </Typography>

      <Footer />
    </ContainerBox>
  );
};

export default SignIn;
