import React from 'react-redux';
import staticTexts from 'texts';

/**
 * Show a summary of changes to the privacy policy and terms of serivce
 * and prompt the user to consent. Show only the documents which have
 * been updated since the users last consent. Link to the full text of
 * any documents which have been updated.
 */
const PrivacyConsent = ({
  privacy,
  requiredConsents,
  whichDocument,
  navigate,
  styles,
  header,
  buttons,
}) => {
  const privacyIntroLine1 = staticTexts.privacyIntroLine1 ? (
    <p>{staticTexts.privacyIntroLine1}</p>
  ) : (
    <p>Hi there!</p>
  );
  const privacyIntroLine2 = staticTexts.privacyIntroLine2 ? (
    <p>{staticTexts.privacyIntroLine2}</p>
  ) : (
    <p>We are announcing updates to our {getFullTextLink()}</p>
  );
  const privacyIntroLine3 = staticTexts.privacyIntroLine3 ? (
    <p>{staticTexts.privacyIntroLine3}</p>
  ) : (
    <p>
      Please review to understand the changes that may be important to you.
      Below is a summary of the key changes:
    </p>
  );
  const privacyIntroLine4 = staticTexts.privacyIntroLine4 ? (
    <p>{staticTexts.privacyIntroLine4}</p>
  ) : null;
  const privacyIntroLine5 = staticTexts.privacyIntroLine5 ? (
    <p>{staticTexts.privacyIntroLine5}</p>
  ) : null;

  if (privacy) {
    const { terms_of_service, privacy_policy } = privacy;

    return (
      <>
        {header}
        <div className={styles.scroller}>
          {privacyIntroLine1}
          {privacyIntroLine2}
          {privacyIntroLine3}
          {privacyIntroLine4}
          {privacyIntroLine5}

          {requiredConsents.terms_of_service ? (
            <>
              <h2 className={styles.subtitle}>
                Terms of Service Summary Of Changes
              </h2>
              {terms_of_service && terms_of_service.summaryOfChanges ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: privacy.terms_of_service.summaryOfChanges,
                  }}
                />
              ) : (
                <div className={styles.none}>None</div>
              )}
            </>
          ) : null}

          {requiredConsents.privacy_policy ? (
            <>
              <h2 className={styles.subtitle}>
                Privacy Policy Summary Of Changes
              </h2>
              {privacy_policy && privacy_policy.summaryOfChanges ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: privacy.privacy_policy.summaryOfChanges,
                  }}
                />
              ) : (
                <div className={styles.none}>None</div>
              )}
            </>
          ) : null}
        </div>
        {buttons}
      </>
    );
  } else {
    return null;
  }

  /**
   * Link to the full text of privacy policy, tos, or both, depending
   * which document has been updated
   *
   * @returns string
   */
  function getFullTextLink() {
    const termsOfServiceLink = (
      <a href="/privacy" onClick={navigate.viewTermsFull}>
        Terms of Service
      </a>
    );
    const privacyPolicyLink = (
      <a href="/privacy" onClick={navigate.viewPrivacyPolicyFull}>
        Privacy Policy
      </a>
    );

    const links = {
      privacy_policy: privacyPolicyLink,
      terms_of_service: termsOfServiceLink,
      both: (
        <>
          {termsOfServiceLink} and {privacyPolicyLink}
        </>
      ),
    };

    return links[whichDocument];
  }
};

export default PrivacyConsent;
