import store from '../store';
import * as routes from '../constants/routes';
import { privacyConsentGiven } from './helpers';

// Privacy policy switcher.
const privacyEnabled = true;

// Phone verification flag.
export const phoneVerificationEnabled = false;

/**
 * Helper to log routing actions
 * on all environments except prod.
 */
const wesaluteLog = (...args) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log(args);
  }
};

/**
 * Common get next route method.
 *
 * @returns void
 */
export default function getCustomRoute(history) {
  // Get redux store state.
  const state = store.getState();
  const authUser = state.sessionState.authUser;
  wesaluteLog('authUser', authUser);
  const accountData = state.accountState.account;
  wesaluteLog('accountData', accountData);
  const privacyData = state.privacyReducer.data;
  wesaluteLog('privacyData', privacyData);
  // Need to hide verify app verification
  // screen from BrandConnection users'.
  const loginChallenge = state.sessionDBState.data.urlQuery?.login_challenge;
  // Hide certain pages for Household members and preregistered users.
  const login = state.sessionDBState.data.urlQuery?.login;
  // Hide certain pages for new regular users.
  const signin = state.sessionDBState.data.urlQuery?.signin;

  // Offers pages.
  const returnTo = state.sessionDBState.data.urlQuery?.return_to;
  const isCheckoutApp =
    returnTo && /checkout(-[a-z]+)?\.wesalute.com/.test(returnTo);
  const isOfferPage =
    returnTo &&
    (/\.wesalute.com\/offers\//.test(returnTo) ||
      /\.wesalute.com\/node\/[0-9]+/.test(returnTo));

  // Sign in redirect for non logged in user.
  if (authUser === false) {
    wesaluteLog('Not logged in');
    history.push(routes.SIGN_IN);
  } else if (!accountData?.verification?.email) {
    // Email verification redirect.
    wesaluteLog('Non verified email');
    history.push(routes.EMAIL_VERIFICATION);
  } else if (
    privacyEnabled &&
    // In case of social login time is different even for new user.
    // And additionalUserInfo is not available in Redux authUser.
    (authUser.metadata.createdAt.slice(0, 10) ===
      authUser.metadata.lastLoginAt.slice(0, 10) ||
      login ||
      signin) &&
    !privacyConsentGiven(accountData, privacyData)
  ) {
    // New user.
    wesaluteLog('Privacy register of new user (done in background)');
    history.push(routes.PRIVACY_REGISTER);
  } else if (
    privacyEnabled &&
    !login &&
    !privacyConsentGiven(accountData, privacyData)
  ) {
    wesaluteLog('Privacy agreement for existing user (done via form submit)');
    history.push(routes.PRIVACY);
  } else if (
    !accountData?.verified &&
    !loginChallenge &&
    !login &&
    !isCheckoutApp
  ) {
    wesaluteLog('Ask for VerifyApp verification (not fully verified)');
    // Suggest VerifyApp verification.
    history.push({
      pathname: routes.VERIFY_APP_VERIFICATION,
      state: {
        isOfferPage,
      },
    });
  } else {
    wesaluteLog('Send to /verify redirect page');
    // Final redirect to jump page.
    history.push(routes.VERIFY);
  }
}
