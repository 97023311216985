import React from 'react';
import { useWesaluteRecaptcha } from 'hooks';
import Preloader from 'components/Preloader';
import EmailConfirmForm from 'components/Sign/EmailConfirm/EmailConfirmForm';
import staticTexts from 'texts';

const EmailConfirm = () => {
  const [recaptcha] = useWesaluteRecaptcha('email_confirm');

  if (!recaptcha) {
    return <Preloader title={staticTexts.NewPasswordLoading} />;
  } else {
    return <EmailConfirmForm />;
  }
};

export default EmailConfirm;
