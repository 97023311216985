import React from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';

import * as routes from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '72px',
    background: '#F8F9FA',
    marginBottom: '41px',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#F8F9FA',
    [theme.breakpoints.up('md')]: {
      marginBottom: '87px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: '91px',
      padding: '19px 60px',
    },
    '& .logo': {
      width: 75,
    },
  },
  business: {
    '& .logo': {
      width: '100px',
      cursor: 'pointer',
    },
  },
  partnerLogoWrapper: {
    position: 'relative',
    height: '29px',
    borderLeft: '1px solid #B3BBCA',
    marginLeft: '10px',
    padding: '2px 0 2px 10px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
      padding: '4px 0 4px 20px',
      height: '47px',
    },
  },
  partnerLogo: {
    maxHeight: '100%',
  },
  container: {},
  marginHelper: {
    marginTop: '5px',
    marginBottom: '11px',
  },
  field: {
    borderBottom: '0 none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    '&:before': {
      display: 'none',
    },
  },
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="static" className={classes.header} elevation={0}>
      <img
        src={props.logo}
        alt={'WeSalute'}
        className={'logo'}
        onClick={() => history.push(routes.SIGN_UP)}
      />
      {props.brandData?.logo && (
        <div className={classes.partnerLogoWrapper}>
          <img
            src={props.brandData.logo}
            alt={props.brandData.label}
            className={classes.partnerLogo}
          />
        </div>
      )}
    </AppBar>
  );
};

export default Header;
