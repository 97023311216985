import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { db, helpers } from 'actions';
import * as routes from 'constants/routes';
import staticTexts from 'texts';

/**
 * Helper hook to handle Segment page event processing.
 */
export const useWesaluteRecaptcha = (page) => {
  const dispatch = useDispatch();
  const allowTests = useSelector((state) => {
    if (
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'stage'
    ) {
      return Boolean(state.sessionDBState.data.urlQuery?.allowTests);
    }
    return false;
  });
  const [recaptcha, setRecaptcha] = useState(false);

  useEffect(() => {
    initRecaptcha();
    // Load Recaptcha token every 170 sec (3 minutes - 10 sec shift).
    const recaptchaInterval = setInterval(() => initRecaptcha(), 170000);
    // Clear interval when component unmount.
    return () => clearInterval(recaptchaInterval);
    // eslint-disable-next-line
  }, []);

  const initRecaptcha = async () => {
    if (
      allowTests ||
      process.env.REACT_APP_ENV === 'local-dev' ||
      process.env.REACT_APP_ENV === 'emulator'
    ) {
      setRecaptcha(true);
      return;
    }
    return helpers
      .recaptchaChecker(page)
      .then(() => {
        setRecaptcha(true);
      })
      .catch((error) => {
        dispatch(
          db.setErrorPage(
            staticTexts.RecaptchaV3ErrorTitle,
            staticTexts.RecaptchaV3ErrorDescription,
            'error',
            false,
            staticTexts.RecaptchaV3ErrorBtn,
            routes.SIGN_UP
          )
        );
        // Track only non 403 errors.
        if (error?.message !== 'Request failed with status code 403') {
          Sentry.captureException(error);
          console.error(error);
        }
      });
  };

  return [recaptcha];
};
