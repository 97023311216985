import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const authRef = firebase.auth();
const functionsRef = firebase.functions();

//Uncomment emulator config in .env.local to use emulator
if (process.env.REACT_APP_USE_EMULATOR) {
  functionsRef.useEmulator('localhost', process.env.REACT_APP_EMULATOR_PORT);
}

const facebook_provider = new firebase.auth.FacebookAuthProvider();
const google_provider = new firebase.auth.GoogleAuthProvider();
const phone_provider = firebase.auth.PhoneAuthProvider.PROVIDER_ID;
const persistenceNone = firebase.auth.Auth.Persistence.NONE;
const persistenceSession = firebase.auth.Auth.Persistence.SESSION;
const persistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
const recaptcha = (elm, config) => {
  return new firebase.auth.RecaptchaVerifier(elm, config);
};

const emailAuth = (email, password) =>
  firebase.auth.EmailAuthProvider.credential(email, password);

export {
  firebase,
  authRef,
  functionsRef,
  google_provider,
  facebook_provider,
  phone_provider,
  recaptcha,
  persistenceNone,
  persistenceSession,
  persistenceLocal,
  emailAuth,
};
