import { authRef, functionsRef } from '../firebase-config/firebase';
import { api } from '../actions';
import store from '../store';
import { load } from 'recaptcha-v3';
import { APPS_DOMAIN_OLD, APPS_DOMAIN } from '../constants/const';

// Create initial user record.
export const doCreateUser = (data) => {
  if (!authRef?.currentUser?.uid) {
    return;
  }
  const state = store.getState();
  const source = state.sessionDBState.data.returnType;

  let dateNow = new Date().getTime();
  let additionalInfo = {
    created: dateNow,
    changed: dateNow,
    signupSource: source !== 'widget' ? 'platform' : 'widget',
    modifiedBy: 'authApp',
    revisionId: 1,
  };

  const createUser = functionsRef.httpsCallable(
    'groupAuthHelpers-doCreateUser'
  );
  return createUser({ ...data, ...additionalInfo });
};

// Initiate recaptcha.
export const recaptchaChecker = (action) => {
  const key = process.env.REACT_APP_RECAPTCHA_KEY;
  // If action is login, then check the token and return result.
  return load(key).then((recaptcha) => {
    return recaptcha.execute(action).then((token) => {
      return api.recaptchaChecker(token, action);
    });
  });
};

// Check if user already exists in the DB.
export const checkExistingUser = (email, facebook, skipAuthCheck) => {
  const checkExistingUser = functionsRef.httpsCallable(
    'groupAuthHelpers-checkExistingUser'
  );
  return checkExistingUser({ email, facebook, skipAuthCheck });
};

// Verify Auth Email.
export const verifiedEmailFlag = (email) => {
  const verifiedEmailFlag = functionsRef.httpsCallable(
    'groupAuthHelpers-verifyAuthEmail'
  );
  return verifiedEmailFlag({ email });
};

// Check if user's phone already exists in the DB.
export const checkExistingPhone = (phone) => {
  const checkExistingPhone = functionsRef.httpsCallable(
    'groupAuthHelpers-checkExistingPhone'
  );
  return checkExistingPhone({ phone });
};

// Update or create users Firestore auth record storage.
export const doUpdateAuthRecord = () => {
  if (!authRef?.currentUser?.uid) {
    return;
  }
  const updateAuthRecord = functionsRef.httpsCallable(
    'groupAuthAccounts-updateAuthRecord'
  );

  return updateAuthRecord();
};

// Get authToken.

// Get authToken.
export const doGetCsrfToken = () => {
  if (!authRef?.currentUser?.uid) {
    return;
  }
  const getCsrfToken = functionsRef.httpsCallable(
    'groupAuthAccounts-getCsrfToken'
  );

  return getCsrfToken();
};

// Hydra action helper.
export const doHydraAction = (data) => {
  const getRedirectUrl = functionsRef.httpsCallable(
    'groupBrandConnectionsLight-hydraAuthAppHelper'
  );

  return getRedirectUrl(data);
};

// Check if name is valid
export const isValidName = (text) => {
  const reg = RegExp("^[ a-zA-Z'.-]*$");
  if (!!text && reg.test(String(text))) {
    return true;
  } else {
    return false;
  }
};

//Write to firestore the tos and privacy policy version accepted by the user
export const recordConsent = async (pp_version, tos_version) => {
  //Make sure null values are never submitted to the backend
  if (!pp_version) pp_version = 0;
  if (!tos_version) tos_version = 0;

  return await functionsRef.httpsCallable('groupAuthHelpers-recordConsent')({
    pp_version,
    tos_version,
  });
};

/**
 * Helper to get App URL.
 *
 * @return {string}
 */
export const getAppUrl = (name) => {
  let url = `https://${name}`;
  const envs = ['dev', 'stage'];
  if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
    url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev';
  }
  // TODO change to new app domain once switched.
  const currentDomain = window.location.hostname.endsWith(APPS_DOMAIN_OLD)
    ? APPS_DOMAIN_OLD
    : APPS_DOMAIN;
  return url + '.' + currentDomain;
};

/**
 * This function checks to see if the user has consented to the
 * latest version of the privacy policy and terms of service.
 *
 * @param {DocumentData} accountData object fetched from firestore
 * @param {object} privacy the privacy collection fetched from firestore
 * @returns boolean
 */
export const privacyConsentGiven = (accountData, privacy) => {
  if (accountData && privacy) {
    const userTermsOfServiceVersion =
      accountData?.legal_consent?.tos_version || 0;
    const userPrivacyPolicyVersion =
      accountData?.legal_consent?.pp_version || 0;
    const termsOfServiceVersion = privacy?.terms_of_service?.versionNumber;
    const privacyPolicyVersion = privacy?.privacy_policy?.versionNumber;
    if (
      userTermsOfServiceVersion === termsOfServiceVersion &&
      userPrivacyPolicyVersion === privacyPolicyVersion
    ) {
      return true;
    }
    return false;
  }
};
