const INITIAL_STATE = {
  title: '',
  type: 'info', // 'error' | 'warning' | 'info' | 'success'
  active: false,
};

function notificationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_NOTIFICATION': {
      return action.payload;
    }
    default:
      return state;
  }
}

export default notificationReducer;
