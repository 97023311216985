const INITIAL_STATE = {
  status: 'loading',
  plans: {},
  selected: { id: '', plan: '' },
};

const applySetAccounts = (state, action) => ({
  status: 'loaded',
  plans: action.payload,
  selected: { id: '', plan: '' },
});

function plansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_PLANS': {
      if (action.payload) {
        return applySetAccounts(state, action);
      } else {
        return false;
      }
    }
    case 'SELECT_PLAN': {
      return {
        status: 'planSelected',
        plans: state.plans,
        selected: action.payload,
      };
    }
    default:
      return state;
  }
}

export default plansReducer;
