import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { setNotification } from 'actions/db';
import { Alert, Collapse } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  notification: {
    padding: '0 16px',
    marginTop: '-20px',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '90px',
      width: '100%',
      padding: '19px 60px',
      margin: '0',
    },
  },
  alert: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notificationState);

  /**
   * Helper to clear notifications.
   */
  useEffect(() => {
    let timeoutId;

    if (notification.active) {
      timeoutId = setTimeout(() => {
        dispatch(setNotification());
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification]);

  if (!notification || !notification.type) return <></>;

  return (
    <Collapse in={notification?.active} className={classes.notification}>
      <Alert
        severity={notification.type}
        onClose={() => dispatch(setNotification())}
        className={classes.alert}
      >
        {notification.title}
      </Alert>
    </Collapse>
  );
};

export default Notification;
