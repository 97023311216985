const INITIAL_STATE = {
  alert: {
    title: false,
    desc: false,
    type: false,
  },
  desc: false,
  descTitle: false,
  btn: {
    title: false,
    page: false,
    external: false,
    close: false,
    signout: false,
  },
  active: false,
};

function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ERROR_PAGE': {
      return action.payload;
    }
    default:
      return state;
  }
}

export default errorReducer;
