import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from 'actions';

/**
 * Helper hook to handle Segment page event processing.
 */
export const useGetAccountData = (fields, frequency = 1000) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const [accountDataLoaded, setAccountDataLoaded] = useState(false);

  useEffect(() => {
    if (authUser) {
      getAccountData();
      // Load account data every second until .
      const getAccountDataInterval = setInterval(
        () => getAccountData(),
        frequency
      );
      // Clear interval when component unmount.
      return () => clearInterval(getAccountDataInterval);
    }
    // eslint-disable-next-line
  }, [authUser]);

  const getAccountData = async () => {
    const field = fields.split('.').reduce((o, i) => o?.[i], accountData);
    // Load account data until required field is present.
    if (authUser) {
      if (!field) {
        dispatch(db.fetchAccountInfo(authUser));
      } else {
        setAccountDataLoaded(true);
      }
    }
  };

  return [accountDataLoaded];
};
