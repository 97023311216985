import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWesaluteRecaptcha } from 'hooks';
import * as Sentry from '@sentry/browser';
import { auth, db } from 'actions';
import * as routes from 'constants/routes';
import Preloader from 'components/Preloader';
import PasswordForgetForm from 'components/Sign/PasswordForget/PasswordForgetForm';
import staticTexts from 'texts';

const INITIAL_STATE_FIELDS = {
  email: '',
};

const PasswordForget = () => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState(INITIAL_STATE_FIELDS);
  const [error, setError] = useState(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const [recaptcha] = useWesaluteRecaptcha('password_reset');
  const [loading, setLoading] = useState(false);
  const [fieldsError, setFieldsError] = useState({});
  const authUser = useSelector((state) => state.sessionState.authUser);

  useEffect(() => {
    // If a user is logged in, log them out.
    if (authUser) {
      auth.doSignOut();
    }
  }, []);

  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError])

  const setInitialState = () => {
    setFields(INITIAL_STATE_FIELDS);
    setError(null);
    setIsInvalid(true);
    setLoading(false);
    setFieldsError({});
  };

  const onSubmit = (event) => {
    const { email } = fields;
    setLoading(true);
    sendEmail(email);
    event.preventDefault();
  };

  const sendEmail = (email) => {
    auth
      .doPasswordReset(email)
      .then(() => {
        setInitialState();
        let hiddenEmail = email.replace(/(.{2})(.*)(?=@)/, (gp1, gp2, gp3) => {
          for (let i = 0; i < gp3.length; i++) {
            gp2 += '*';
          }
          return gp2;
        });
        let successStatic = staticTexts.PasswordForgetSuccessDescription;
        let successText = successStatic.replace('@email', hiddenEmail);
        setLoading(false);
        dispatch(
          db.setErrorPage(
            staticTexts.PasswordForgetSuccessTitle,
            successText,
            'success',
            staticTexts.PasswordForgetSuccessMoreDescription,
            staticTexts.PasswordForgetSuccessButton,
            routes.SIGN_IN,
            false,
            true
          )
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('password-reset', error);
        setError(error);
        setLoading(false);
      });
  };

  const doValidateEmail = (email) => {
    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationNotValid,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        email: false,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Set field.
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    if (recaptcha && fieldsError.email === false) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  if (loading || !recaptcha) {
    return <Preloader title={staticTexts.PasswordForgetLoading} />;
  } else {
    return (
      <div className="page-forgot-password">
        <PasswordForgetForm
          onSubmit={onSubmit}
          sendEmail={sendEmail}
          email={fields.email}
          isInvalid={isInvalid}
          error={error}
          onChangeInput={onChangeInput}
          inputValidation={inputValidation}
          fieldsError={fieldsError}
        />
      </div>
    );
  }
};

export default PasswordForget;
