const INITIAL_STATE = {
  error: false,
  status_face_done: false,
  status_info_done: false,
};

function captureIdReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CAPTURE_INFO_ID_DONE': {
      return {
        ...state,
        status_info_done: action.payload,
      };
    }
    case 'CAPTURE_FACE_ID_DONE': {
      return {
        ...state,
        status_face_done: action.payload,
      };
    }
    case 'CAPTURE_ID_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export default captureIdReducer;
