import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const ContainerBox = (props) => {
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const useStyles = makeStyles(() => defaultDashboardStyles);
  const classes = useStyles();

  return (
    <Box boxShadow={{ xs: 0, sm: 0, md: 6 }} className={classes.box}>
      <Box p={{ xs: 2, md: 10 }} className={classes.boxInner}>
        {props.children}
      </Box>
    </Box>
  );
};

export default ContainerBox;
