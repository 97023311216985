import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWesaluteRecaptcha } from 'hooks';
import Preloader from 'components/Preloader';
import SignInForm from 'components/Sign/SignIn/SignInForm/SignInForm';

import { db, auth, analytics, getCustomRoute } from 'actions';
import staticTexts from 'texts';

const INITIAL_STATE_FIELDS = {
  email: '',
  password: '',
};

const SignInFormContainer = () => {
  const { setErrorPage } = db;
  const history = useHistory();
  const dispatch = useDispatch();
  const [fields, setFields] = useState(INITIAL_STATE_FIELDS);
  const [finish, setFinish] = useState(false);
  const [recaptcha] = useWesaluteRecaptcha('login');
  const [error, setError] = useState(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [fieldsError, setFieldsError] = useState({});
  const accountData = useSelector((state) => state.accountState.account);
  const remember = useSelector(
    (state) => state.sessionDBState.data.rememberCredentials
  );

  useEffect(() => {
    if (accountData) {
      getCustomRoute(history);
    }
  }, [accountData]);

  useEffect(() => {
    analytics.page('Sign In Form');
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError, fields])

  useEffect(() => {
    if (finish && recaptcha) {
      const { email, password } = fields;

      setError(null);
      setLoading(true);

      // Sign in with email and password.
      auth
        .doSignInWithEmailAndPassword(email, password)
        .then((credentials) => {
          setInitialState();
          dispatch(db.fetchAccountInfo(credentials.user));
        })
        .catch((error) => {
          // Process Firebase Auth existing accounts messages.
          auth
            .processSignInMethodsForEmail(
              email,
              staticTexts,
              dispatch,
              setErrorPage,
              error
            )
            .then((status) => {
              if (!status) {
                setError(error);
                setFinish(false);
                setLoading(false);
              }
            });

          if (error.code === 'auth/wrong-password') {
            console.error('email-pass-sign-in', error);
          }
        });
    }
    // eslint-disable-next-line
  }, [recaptcha, finish])

  const setInitialState = () => {
    setFields(INITIAL_STATE_FIELDS);
    setError(null);
    setIsInvalid(true);
    setLoading(false);
    setPasswordType('password');
    setFieldsError({});
  };

  const signInWithEmailAndPassword = (event) => {
    event.preventDefault();

    setFinish(true);
    setLoading(true);
  };

  const doValidateEmail = (email) => {
    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationNotValid,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        email: false,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Set field.
    // Remove empty spaces if field is email.
    setFields({
      ...fields,
      [fieldName]: event.target.id === 'email' ? fieldValue.trim() : fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    const { email, password } = fields;

    if (
      ((typeof fieldsError.email === 'undefined' && email !== '') ||
        fieldsError.email === false) &&
      ((typeof fieldsError.password === 'undefined' && password !== '') ||
        fieldsError.password === false)
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const togglePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  if (loading) {
    return <Preloader title={staticTexts.SignInFormLoader} />;
  } else {
    return (
      <SignInForm
        onSubmit={signInWithEmailAndPassword}
        password={fields.password}
        passwordType={passwordType}
        togglePasswordType={togglePasswordType}
        onChangeInput={onChangeInput}
        email={fields.email}
        isInvalid={isInvalid}
        error={error}
        inputValidation={inputValidation}
        fieldsError={fieldsError}
        remember={remember}
      />
    );
  }
};

export default SignInFormContainer;
