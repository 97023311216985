import React, { useState } from 'react';
import DisconnectProvider from 'components/ManageProviders/Providers/DisconnectProvider';
import AddSocialProvider from 'components/ManageProviders/Providers/AddSocialProvider';
import { PASSWORD_PROVIDER } from 'components/ManageProviders/const';

const Provider = ({ email, providerId }) => {
  const [showConnect, setShowConnect] = useState(false);
  return showConnect ? (
    providerId !== PASSWORD_PROVIDER && <AddSocialProvider type={providerId} />
  ) : (
    <li key={providerId}>
      {providerId && email && (
        <>
          <span>
            {providerId} {email}
          </span>
          {providerId !== PASSWORD_PROVIDER && (
            <DisconnectProvider
              type={providerId}
              setShowConnect={setShowConnect}
            />
          )}
        </>
      )}
    </li>
  );
};

export default Provider;
