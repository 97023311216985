import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { db } from '../../../../actions';
import Typography from '@mui/material/Typography';
import { TRACK_CLICK_CLASS } from '../../../../constants/const';

const BrandsBlock = () => {
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const styleOverride = {};

  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles((theme) => ({
    ...customStyles,
    brandsBlock: {
      marginBottom: 24,
    },
    brandsBlockHeader: {
      fontSize: 24,
      lineHeight: 1.29167,
      letterSpacing: '-0.02em',
      marginBottom: 24,
    },
    brandsListWrapper: {
      padding: 0,
      maxWidth: 280,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
      },
    },
    brandsList: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      marginLeft: -12,
      marginRight: -12,
      '& li': {
        position: 'relative',
        width: '100%',
        paddingLeft: 12,
        paddingRight: 12,
        display: 'block',
        marginBottom: 24,
        flex: '0 0 33.333333%',
        maxWidth: '33.333333%',
        [theme.breakpoints.up('md')]: {
          flex: '0 0 25%',
          maxWidth: '25%',
        },
        [theme.breakpoints.up('lg')]: {
          flex: '0 0 16.666667%',
          maxWidth: '16.666667%',
        },
      },
      '& a': {
        display: 'block',
      },
      '& img': {
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 8,
        boxShadow:
          '0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1)',
      },
    },
  }));
  const classes = useStyles();

  const [title, setTitle] = useState('100+ WeSalute Brands');
  const [brands, setBrands] = useState(null);

  useEffect(() => {
    db.fetchWesaluteBrands().then((data) => {
      if (data?.title) {
        setTitle(data?.title);
      }
      if (data?.brands) {
        setBrands(data?.brands);
      }
    });
  }, []);

  return (
    <div className={classes.brandsBlock}>
      <Typography
        variant="body2"
        className={classes.brandsBlockHeader}
        component="h2"
      >
        {title}
      </Typography>
      <div className={classes.brandsListWrapper}>
        <ul className={classes.brandsList}>
          {brands &&
            brands.map((item, index) => (
              <li key={`item-${index}`} className={`brand-item-${index}`}>
                <a href={item?.url} target={'_blank'} rel={'noreferrer'}>
                  <img
                    className={TRACK_CLICK_CLASS}
                    src={item?.image?.href}
                    alt={item?.image?.alt}
                    data-segment={item?.action}
                  />
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default BrandsBlock;
