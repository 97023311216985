import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { analytics, auth } from 'actions';
import Preloader from 'components/Preloader';
import * as routes from 'constants/routes';
import { HYDRA_BRAND_CONNECTIONS_USED } from 'constants/hydra';
import staticTexts from 'texts';

const SignOut = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const returnTo = useSelector(
    (state) => state?.sessionDBState?.data?.urlQuery?.return_to
  );
  const history = useHistory();
  const brandConnectionsUsed = window.localStorage.getItem(
    HYDRA_BRAND_CONNECTIONS_USED
  );

  useEffect(() => {
    analytics.page('Sign Out');
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (authUser === false) {
      history.push(routes.SIGN_IN);
    } else if (brandConnectionsUsed) {
      // Unset Brand Connections local storage item.
      window.localStorage.removeItem(HYDRA_BRAND_CONNECTIONS_USED);
      // Redirect to Brand Connections redirect Hydra URL.
      window.location.href = '/api/logout-redirect';
    } else if (authUser) {
      auth
        .doSignOut()
        .then(() => {
          // Reset Segmentio analytics during logout.
          analytics.reset();
          if (window.opener) {
            // Close on Logout in BrandConnections and MilVetID.
            window.close();
          } else if (returnTo) {
            window.location.href = returnTo;
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
        });
    }
  }, [authUser]);

  return <Preloader title={staticTexts.SignOutLoader} />;
};

export default SignOut;
