import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { api, helpers } from 'actions';
import { authRef } from 'firebase-config/firebase';
import EmailDialog from 'components/Sign/EmailDialog/EmailDialog';
import * as routes from 'constants/routes';
import staticTexts from 'texts';

const INITIAL_STATE_FIELDS = {
  firstname: '',
  lastname: '',
  email: '',
};

const EmailDialogContainer = (props) => {
  const {
    authUserEmail,
    authUserFirstName,
    authUserLastName,
    onSubmitEmail,
    showEmailForm,
    setShowEmailForm,
    isNewUser,
  } = props;

  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);

  const [fields, setFields] = useState(INITIAL_STATE_FIELDS);
  const [isInvalid, setIsInvalid] = useState(true);
  const [finish, setFinish] = useState(false);
  const [emailValidationInProgress, setEmailValidationInProgress] =
    useState(false);
  const [fieldsError, setFieldsError] = useState({});

  useEffect(() => {
    if (finish) {
      const { firstname, lastname, email } = fields;

      authRef.currentUser
        .updateEmail(email)
        .then(() => {
          onSubmitEmail(email, firstname, lastname);
          setEmailValidationInProgress(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          setEmailValidationInProgress(false);
          setFieldsError({
            ...fieldsError,
            email:
              error.code === 'auth/requires-recent-login'
                ? 'Operation requires recent login'
                : error.code,
          });
        });
    }
    // eslint-disable-next-line
  }, [finish])

  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError, fields, emailValidationInProgress])

  useEffect(() => {
    // Set fields.
    if (authUserEmail) {
      setFields({
        ...fields,
        email: authUserEmail,
      });
    }
    if (authUserFirstName) {
      setFields({
        ...fields,
        firstname: authUserFirstName,
      });
    }
    if (authUserLastName) {
      setFields({
        ...fields,
        lastname: authUserLastName,
      });
    }
    // eslint-disable-next-line
  }, [authUserEmail, authUserFirstName, authUserLastName])

  const onSubmit = () => {
    setEmailValidationInProgress(true);
    setFinish(true);
  };

  const doValidateEmail = (email) => {
    setEmailValidationInProgress(true);

    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regMilEmail = RegExp(
      '^[A-Za-z0-9._%+-]+@([a-z0-9][-a-z0-9\\.]*[a-z0-9].mil)$'
    );

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationNotValid,
      });
      setEmailValidationInProgress(false);
    } else if (regMilEmail.test(String(email).toLowerCase())) {
      // Check if it is a .mil email.
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationActiveDutyEmail,
      });
      setEmailValidationInProgress(false);
    } else {
      // Check if it is an active domain email
      // and if it is unique.
      api
        .verifyEmailDomain(email)
        .then(() => {
          setFinish(true);
          setFieldsError({
            ...fieldsError,
            email: false,
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          const apiError =
            error?.response?.data?.error?.message ?? error?.message;

          setFieldsError({
            ...fieldsError,
            email: apiError,
          });
        })
        .finally(() => {
          setEmailValidationInProgress(false);
        });

      return true;
    }
  };

  const doValidateFirstName = (firstname) => {
    // Validate firstname.
    if (helpers.isValidName(firstname)) {
      setFieldsError({
        ...fieldsError,
        firstname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        firstname: staticTexts.FirstNameVerificationNotValid,
      });
    }
  };

  const doValidateLastName = (lastname) => {
    // Validate lastname.
    if (helpers.isValidName(lastname)) {
      setFieldsError({
        ...fieldsError,
        lastname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        lastname: staticTexts.LastNameVerificationNotValid,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Put email in lowercase and limit the first and last names to 60 characters.
    if (fieldName === 'email') {
      fieldValue = fieldValue.toLowerCase();
    } else if (fieldName === 'firstname' || fieldName === 'lastname') {
      fieldValue = fieldValue.substring(0, 60);
    }

    // Set field.
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (customValidator === 'firstname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateFirstName(e.target.value);
      }
    } else if (customValidator === 'lastname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateLastName(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    const { firstname, lastname, email } = fields;

    if (
      !emailValidationInProgress &&
      ((typeof fieldsError.firstname === 'undefined' &&
        firstname !== '' &&
        helpers.isValidName(firstname)) ||
        fieldsError.firstname === false) &&
      ((typeof fieldsError.lastname === 'undefined' &&
        lastname !== '' &&
        helpers.isValidName(lastname)) ||
        fieldsError.lastname === false) &&
      ((typeof fieldsError.email === 'undefined' && email !== '') ||
        fieldsError.email === false)
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const goToSignIn = () => {
    authUser
      .delete()
      .then(() => {
        setShowEmailForm(false);
        history.push(routes.SIGN_OUT);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('social-delete-current-user', error);
      });
  };

  return (
    <EmailDialog
      isNewUser={isNewUser}
      authUserEmail={authUserEmail}
      onSubmit={onSubmit}
      signLink={goToSignIn}
      showEmailForm={showEmailForm}
      onChangeInput={onChangeInput}
      firstname={fields.firstname}
      lastname={fields.lastname}
      email={fields.email}
      isInvalid={isInvalid}
      fieldsError={fieldsError}
      inputValidation={inputValidation}
      emailValidationInProgress={emailValidationInProgress}
    />
  );
};

export default EmailDialogContainer;
