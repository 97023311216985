import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unlink } from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import Link from '@mui/material/Link';
import { onSetAuthUser } from 'actions/db';

const DisconnectProvider = ({ type, setShowConnect }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);

  const disconnectProvider = async () => {
    if (!authUser?.auth?.currentUser) {
      console.error('User is not loaded yet');
      return;
    }

    await unlink(authUser?.auth?.currentUser, type)
      .then((user) => {
        dispatch(onSetAuthUser(user));
        setShowConnect(true);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <Link data-id="disconnect-provider-link" onClick={disconnectProvider}>
      Disconnect
    </Link>
  );
};

export default DisconnectProvider;
